import {
  Component,
  effect,
  inject,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import {
  HealthscribeRecorderService,
  RECORDING_STATE,
} from '../healthscribe-recorder.service';
import {
  RecordingControlComponent,
  RENDER_MODE,
} from '../recording-control/recording-control.component';
import { AnalyticsService } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { RecordingStepService } from '../recording-step.service';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import {
  format,
  isFuture,
  isPast,
  parseISO,
  subMinutes,
  subSeconds,
} from 'date-fns';
import { get } from 'lodash';
import { ToastMessageService } from '@app/shared/components/toast';

function streamEndingSoon(streamEndsAt: string | null) {
  if (!streamEndsAt) {
    return false;
  }
  const endDate = parseISO(streamEndsAt);
  const fiveMinutesBeforeStreamEnds = subMinutes(endDate, 5);
  return isFuture(endDate) && isPast(fiveMinutesBeforeStreamEnds);
}

function shouldEndStream(streamEndsAt: string | null) {
  if (!streamEndsAt) {
    return false;
  }
  const endDate = parseISO(streamEndsAt);
  const thirtySecondsBeforeStreamEnds = subSeconds(endDate, 30);
  return isFuture(endDate) && isPast(thirtySecondsBeforeStreamEnds);
}

@Component({
  selector: 'omg-recording-manager',
  templateUrl: './recording-manager.component.html',
})
export class RecordingManagerComponent {
  @Input() mode: RENDER_MODE;
  @Input({ required: true }) appointmentId: string;

  @ViewChild('recordingControl') recordingControl: RecordingControlComponent;

  scribeStreamingInChartEnabled: boolean = false;
  private recordingStepService = inject(RecordingStepService);

  private readonly recordingManagerAnalyticsProps = {
    ...DefaultAnalyticsProps,
    component: 'Recording Manager',
  };

  private healthscribeRecorderService = inject(HealthscribeRecorderService);
  private analyticsService = inject(AnalyticsService);

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private toastMessageService: ToastMessageService,
  ) {
    this.scribeStreamingInChartEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.scribeStreamingInChart,
      false,
    );
    let toastShowing = false;

    effect(onCleanup => {
      if (this.scribeStreamingInChartEnabled && this.#streamEndsAt()) {
        const interval = setInterval(() => {
          if (shouldEndStream(this.#streamEndsAt())) {
            this.endRecording();
            this.toastMessageService.clear();
          } else if (streamEndingSoon(this.#streamEndsAt()) && !toastShowing) {
            this.toastMessageService.add({
              severity: 'warn',
              summary: 'Scribe time limit approaching',
              detail: `The visit recording will automatically end at ${format(
                parseISO(this.#streamEndsAt() || ''),
                'h:mmaaa',
              )} and a summary will be generated.`,
              sticky: true,
            });
            toastShowing = true;
            // setShowWarningBanner(true);
          }
        }, 10_000);
        onCleanup(() => {
          clearInterval(interval);
        });
      }
    });
  }
  #streamEndsAt = signal(null);

  recordingState = this.healthscribeRecorderService.recordingState;

  currentDurationMs = this.healthscribeRecorderService.currentDuration;

  pauseRecording(): void {
    this.healthscribeRecorderService.pauseRecording(RECORDING_STATE.PAUSED);
    this.analyticsService.track(
      AnalyticsEvent.HealthScribePauseRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }

  async record(): Promise<void> {
    const appointmentRecording =
      await this.healthscribeRecorderService.startRecording(this.appointmentId);
    this.#streamEndsAt.set(get(appointmentRecording, 'streamEndsAt', null));
    this.analyticsService.track(
      AnalyticsEvent.HealthScribeStartRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }

  endRecording(): void {
    this.recordingStepService.endRecording();
    this.analyticsService.track(
      AnalyticsEvent.HealthScribeSummarizeRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }
}
