import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiConfigOptions } from '@app/core/api/api.type';
import { camelCase, snakeCase } from '@app/utils';
import { ClientApplicationHttpHeaders } from './api-headers';

const baseUrl = environment.api.baseUrl;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    endpoint: string,
    params?: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ): Observable<T> {
    const headers = this.getHeaders(config?.headers);
    const response$ = this.http.get<T>(`${baseUrl}${endpoint}`, {
      ...config,
      params,
      headers,
    });
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  save<T>(
    endpoint: string,
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const headers = this.getHeaders(config?.headers);
    const response$ = this.http.post<T>(`${baseUrl}${endpoint}`, data, {
      ...config,
      headers,
    });
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  update<T>(
    endpoint: string,
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const headers = this.getHeaders(config?.headers);
    const response$ = this.http.put<T>(`${baseUrl}${endpoint}`, data, {
      ...config,
      headers,
    });
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  delete<T>(endpoint: string, config?: ApiConfigOptions) {
    const headers = this.getHeaders(config?.headers);
    return this.http.delete<T>(`${baseUrl}${endpoint}`, { ...config, headers });
  }

  private camelize<T>(response$: Observable<any>) {
    return response$.pipe(map(response => <T>camelCase(response)));
  }

  /**
   * Merges existing headers with ClientApplicationHttpHeaders to aid in application identification
   */
  private getHeaders(
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        },
  ): HttpHeaders {
    return new HttpHeaders({
      ...(headers || {}),
      ...ClientApplicationHttpHeaders,
    });
  }
}
